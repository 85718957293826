<template>
  <div>
    <slideBanner />

    <v-container>
      <v-row class="mt-4" justify="center">
        <v-col cols="12" md="10">
          <about-details></about-details>
        </v-col>
        <v-col cols="12" md="5" v-if="showLogin">
          <!-- <h4>If you have started your application and have not submitted, kindly login to continue your application, don't start a new application. </h4> -->
          <lo-gin v-if="!forgetPassword"/>
          <forget-password v-else />
            <p class="text-center mt-3">
              <v-btn color="primary" small  @click="forgetPassword = !forgetPassword">{{ forgetPassword ? 'login' : 'forgot password'}}</v-btn>
            </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SlideBanner from "@/components/SlideBanner.vue";
import AboutDetails from "@/components/AboutDetails.vue";
import ForgetPassword from '@/components/ForgetPassword.vue';
import LoGin from '@/components/LoGin.vue';

export default {
  name: "Home",
  
  components: {
    SlideBanner,
    AboutDetails,
    ForgetPassword,
    LoGin
  },
  data() {
    return {
      forgetPassword:false,
      showLogin: true,
    };
  }
};
</script>
