<template> 
  <div class="mt-5"  >    
    <div class="mb-10" v-if="showForm">      
      <h2 class="text-center success--text">
        <v-icon color="white" class="">mdi-clipboard-text</v-icon> APPLICATION FORM
      </h2>
    <v-alert  class="text-center error--text">
          All fields are required and important. For any information or enquiry, send sms or WhatsApp message only to <b>+234&nbsp;708 127&nbsp;1856</b>, you can also send email to <b>atipeacehouse@gmail.com.</b> <b>Please no call.</b>
      </v-alert>
    <v-stepper  v-model="e1">
      <v-stepper-header>
        <v-stepper-step editable :complete="e1 > 1" step="1">
          PERSONAL
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :editable="editSteppers" :complete="e1 > 2" step="2">
          EDUCATION
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :editable="editSteppers" :complete="e1 > 3" step="3">
          SPIRITUAL
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step :editable="editSteppers" :complete="e1 > 4" step="4">
          PARENT
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step :editable="editSteppers" :complete="e1 > 5" step="5">
          REFERENCES
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step :editable="editSteppers" step="6">
          UPLOADS
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <PerSonal
            :alert="displayMsg"
            @started-application="startApplication"
            @updated-personal="moveStepper"
            @prev="moveStepper"
          />
        </v-stepper-content>
        <v-stepper-content step="2">
          <EduCation
            :alert="displayMsg"
            :form-id="formId"
            @updated-education="moveStepper"
            @prev="moveStepper"
          />
        </v-stepper-content>
        <v-stepper-content step="3">
          <SpiriTual
            :alert="displayMsg"
            :form-id="formId"
             @updated-spiritual="moveStepper"
            @prev="moveStepper"
          />
        </v-stepper-content>
        <v-stepper-content step="4">
          <PaRent
            :alert="displayMsg"
            :form-id="formId"
             @updated-parent="moveStepper"
            @prev="moveStepper"
          />
        </v-stepper-content>
        <v-stepper-content step="5">
          <ReFerees
            :alert="displayMsg"
            :form-id="formId"
            @updated-referees="moveStepper"
            @prev="moveStepper"
          />
        </v-stepper-content>
        <v-stepper-content step="6">
          <UpLoads
            :alert="displayMsg"
            :form-id="formId"
            :application_no="applicationNo"
            :application_year="applicationYear"
            @application-submitted="closeApplication"
            @prev="moveStepper"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>    
    </div>

    <v-alert class="mx-3" prominent type="success" dense outlined border="left" v-else>
      <v-row align="center">
        <v-col class="grow">
          <p>Thank you for your interest in applying for the Peace House Agricultural Training Institute. This is to acknowledge that your application for admission to the program has been submitted and we have received it.</p>

          <p>We have also sent emails to your nominated referees. The email contains a link to our confidential report which they need to complete for you.</p>

          <p>Note that it is your responsibility to ensure that the reports are completed and submitted on our website.</p> 

          <p>We will also like you to know that you can still access your application form via your online portal on our website.</p>

          <p>You are still able to edit and resend emails to your nominated referees in case they loose the one we sent to them or you made a mistake in typing the email. To do this, you need to log into our website, open your application form and go to the REFERENCES section, EDIT and RESEND EMAIL on each of your referee record if they have not yet submitted their report.</p>

          <p class="error--text"><b>Please note that only those shortlisted for interview will receive further messages.</b></p>

          <p class="info--text">If you have any enquiry, please direct it to: <br> 
          <b>Email:</b> atipeacehouse@gmail.com <br>
          <b>SMS/WhatsApp/Telegram:</b> +234 708 127 1856</p>

          <p>God bless you.</p>

          <p>Yours In Christ,</p>

          <p><b>PHATI Admin</b></p>
    
          <p><b>You can click the close button.</b></p>
        </v-col>
        <v-col cols="12">
          <v-btn to="/" color="primary">close</v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <v-dialog
      v-model="showDialog"
      max-width="30rem"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5 error--text">
          IMPORTANT NOTICE!
        </v-card-title>        
        <v-card-text v-if="editUser">
          <p v-if="!editUser.submitted"><b class="error--text">You have not submitted your application,</b> you must fill all the other required details, upload your documents and submit your application. If you did not submit, your application will not be treated.</p>
          <p>Always click on <b>Save and Continue</b> button.</p>
        </v-card-text>
        <v-card-text v-else-if="applicationStarted">
          <h4>You have started your application, please keep the following details safe:</h4>  
          Your Application Number: <b>{{user.application_no}}</b> <br>
          Your Password: <b>{{user.passwd}}</b> <br>
          Your Phone Number:  <b>{{user.phoneno}}</b> <br>
          <p><b class="error--text">You have not submitted your application</b>, you must fill all the other required details, upload your documents and submit your application. If you did not submit, your application will not be treated </p>
          <p>Always click on <b>Save and Continue</b> button.</p>
        </v-card-text>
          <v-card-text v-else >
          <p>After clicking on <b>Start Application</b> button, please take note of your <b>Application Number</b> and <b>Password</b>, kindly keep them safe.</p> 
          <p><b>You must fill all the other required details and upload your documents. Make sure you get to the final step and submit your application. Your application is not complete until you receive an email of completion from PHATI. </b></p>
          
          <p>In case you did not complete the form, you can come back to login with your <b>User ID</b> (which is your <b>Application Number</b>) and <b>Password</b> to complete the application form and then submit. Please don't start another new application.</p>
          <p>Always click on <b>Save and Continue</b> button.</p> 
          </v-card-text>
          <v-card-text class="error--text">          
          For any information or enquiry, send sms or WhatsApp message only to <b>+234&nbsp;708 127&nbsp;1856</b>, you can also send email to <b>atipeacehouse@gmail.com.</b> <b>Please no call.</b>
        </v-card-text> 
        <v-card-actions>
          <v-btn
            color="green darken-1"
            text
            @click="continueApplication(applicationStarted ? 2 : 1)"
            class="mx-auto"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-snackbar v-model="snackbar" :color="snackColor" bottom :timeout="5000">
      {{ snackMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="dark" v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { snackMixin } from "@/mixins";
import PerSonal from "@/components/Application/PerSonal.vue";
import EduCation from "@/components/Application/EduCation.vue";
import SpiriTual from "@/components/Application/SpiriTual.vue";
import PaRent from "@/components/Application/PaRent.vue";
import ReFerees from "@/components/Application/ReFerees.vue";
import UpLoads from "@/components/Application/UpLoads.vue";

export default {
  name: "Application",

  mixins: [snackMixin],

  props: {
    editUser: {
      type: Object,
      default: null,
    },
  },
  components: {
    PerSonal,
    EduCation,
    SpiriTual,
    PaRent,
    ReFerees,
    UpLoads
  },
  data() {
    return {
      e1: 1,
      snackbar: false,
      snackMsg: "",
      snackColor: "success",
      showForm: true,
      user: {},
      applicationStarted: false,
      showDialog: true,
    };
  },
  computed: {
    editSteppers() {
      return true;
    },
    formId(){
      let uid = this.user.id && this.user.id
      let eid = this.editUser && this.editUser.id
      return uid || eid
    },
    applicationNo(){
      let uno = this.user.application_no && this.user.application_no
      let eno = this.editUser && this.editUser.application_no
      return uno || eno
    },
    applicationYear(){
      let uYear = this.user.application_year && this.user.application_year
      let eYear = this.editUser && this.editUser.application_year
      return uYear || eYear
    },
    shortlisted(){
      return this.editUser && this.editUser.shortlisted == 1;
    }
  },
  methods: {
    moveStepper({ user, step}) {
    user && user.id && (this.user = user);
      step && (this.e1 = step);
    },
    startApplication({ user}) {
      user.id && (this.user = user);
        this.applicationStarted = true
        this.showDialog = true
    },
    displayMsg(
      msg = "Data saved successfully",
      type = "success",
      state = true
    ) {
      this.snackMsg = msg;
      this.snackColor = type;
      this.snackbar = state;
    },

    continueApplication(step) {
    this.showDialog && (this.showDialog = false);
    this.applicationStarted && (this.applicationStarted = false);
      this.e1 = step
    },
    
    closeApplication() {
      this.showForm = false;
    },
  },
};
</script>
