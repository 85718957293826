<template>
    <div class="elevation-2 mt-2">
      <h2 class="text-center success--text">
        <v-icon color="white" class="">mdi-file-document</v-icon> REFERENCE FORM
      </h2>
      <v-alert  class="text-center white--text" color="error" v-if="showForm">
        Please complete the reference form below.
    </v-alert>
<v-card class="mx-3 pb-4" elevation="2" v-if="showForm">
    <v-row class="my-6 px-5">
<v-col cols="12" sm="6" class="mb-0">
    <p class="my-0 py-1">Applicant's Full Name</p>
<v-textarea v-model="dform.applicant_fullname"
    rows="1"
    auto-grow
    dense
    outlined
>  
</v-textarea>
</v-col>
<v-col cols="12" sm="6" class="mb-0">
    <p class="my-0 py-1">The length of time you have known the applicant</p>
<v-textarea v-model="dform.time_length"
    rows="1"
    auto-grow
    dense
    outlined
>  
</v-textarea>
</v-col>


 <v-col cols="12" sm="6" class="mb-0">
     <p class="my-0 py-1">In what capacity do you know the applicant (e.g. lecturer, personal/academic tutor, project supervisor, discipler, spiritual leader/mentor, professional master/head)"</p>
<v-textarea v-model="dform.capacity" 
 rows="1"
    auto-grow
    dense
    outlined
>   
</v-textarea>
</v-col>

 <v-col cols="12" sm="6">
     <p class="my-0 py-1">Salvation experience if known</p>
<v-textarea v-model="dform.salvation_experience"
 rows="1"
    auto-grow
    dense
    outlined
>  
</v-textarea>
</v-col>

 <v-col cols="12" sm="6">
     <p class="my-0 py-1">Applicant’s walk with God if known</p>
<v-textarea v-model="dform.walk_with_God"
 rows="1"
    auto-grow
    dense
    outlined
>   
</v-textarea>

</v-col>
 <v-col cols="12" sm="6">
     <p class="my-0 py-1">The applicant Strength</p>
<v-textarea v-model="dform.strength"
 rows="1"
    auto-grow
    dense
    outlined
>   
</v-textarea>

</v-col>
 <v-col cols="12" sm="6">
     <p class="my-0 py-1">The applicant weaknesses or areas for development</p>
<v-textarea v-model="dform.weaknesses"
 rows="1"
    auto-grow
    dense
    outlined
>   
</v-textarea>
</v-col>

 <v-col cols="12" sm="6">
     <p class="my-0 py-1">Any specific examples of exceptional ability or performance. (This can be particularly helpful when considering an applicant for any specific training opportunities.)</p>
<v-textarea v-model="dform.exceptional_ability"
 rows="1"
    auto-grow
    dense
    outlined
>  
</v-textarea>
</v-col>

 <v-col cols="12" sm="6">
     <p class="my-0 py-1">Their ability to work independently and as a member of a team</p>
<v-textarea v-model="dform.work_ability" 
 rows="1"
    auto-grow
    dense
    outlined
>   
</v-textarea>
</v-col>

 <v-col cols="12" sm="6">
     <p class="my-0 py-1">Comments on any other area that you feel may help us to assess the applicant’s suitability to study with us. (These might cover such topics as English language proficiency, motivation, purpose in life, reliability/dependence)</p>
<v-textarea v-model="dform.other_areas"
 rows="1"
    auto-grow
    dense
    outlined
>   
</v-textarea>
</v-col>
<v-col cols="10" >
    <v-checkbox
    label="I declare that the information provided in this reference by me, is true, complete and correct under God."
    v-model="declaration"
    >
    </v-checkbox>
</v-col>
</v-row>
<v-row  justify="center">
    <v-btn class="mb-7" color="success" :loading="loading" @click="updateReferees" :disabled="!isValid">Submit</v-btn>
</v-row>
</v-card>

<v-alert prominent type="success" border="left" v-if="!showForm">
      <v-row align="center">
        <v-col class="grow">
          Your Reference is Submitted Successfully. Thanks for the time.
          You can click the close button.
        </v-col>
        <v-col class="shrink">
          <v-btn to="/" color="primary">close</v-btn>
        </v-col>
      </v-row>
    </v-alert>
    
    <v-snackbar v-model="snackbar" :color="snackColor" bottom :timeout="5000">
      {{ snackMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="dark" v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
    </div>
</template>

<script>
import { snackMixin } from "@/mixins";
import { apiClient } from "@/services";
    export default {
        name: 'Reference',
        mixins: [snackMixin],
        props: {
            rf_id: String
        },
        data() {           
            return {
                showForm: true,
                declaration: false,
                dform: {
                    applicant_fullname:"",
                    time_length:"",
                    capacity:"",
                    salvation_experience:"",
                    walk_with_God:"",
                    strength:"",
                    weaknesses:"",
                    exceptional_ability:"",
                    work_ability:"",
                    other_areas:""
                }
            }
        },
        computed: {
            isValid (){
                return this.dform.applicant_fullname &&
                    this.dform.time_length &&
                    this.dform.capacity &&
                    this.dform.salvation_experience &&
                    this.dform.walk_with_God &&
                    this.dform.strength &&
                    this.dform.weaknesses &&
                    this.dform.exceptional_ability &&
                    this.dform.work_ability &&
                    this.declaration
            }
        },
        methods: {
           updateReferees() {
            this.dform.type = "reference";
            this.dform.rf_id = this.rf_id;
        // console.log(this.dform);
            this.loading = true;
            // Do Validation here
            apiClient
                .put("/referees", this.dform)
                .then((res) => {
                    // console.log(res.data)
                if (res.data.ok) {
                    this.showForm = false;
                } else {
                    this.displayMsg(res.data, "error");            
                }
                })
                .catch((err) => this.displayMsg(err.message, "error"));
            },
        },
    }
</script>