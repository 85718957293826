<template>
 <v-footer  padless id="footer">
   <v-row>
        <v-col cols="12">
          <div class="text-center">
            <h2>Contact</h2>
            <v-card-text>Isarun, Ondo State, Nigeria</v-card-text>
            <v-card-text class="mt-n7"
              >Email:
              <a href="mail:atipeacehouse@gmail.com"
                >atipeacehouse@gmail.com</a
              ></v-card-text
            >
            <v-card-text class="mt-n7"
              >Website:
              <a href="https://ati.livingseed.org" target="_blank"
                >ati.livingseed.org</a
              ></v-card-text
            >
            <v-card-text class="mt-n7"
              >Telephone: <span class="primary--text">+234 708 127 1856</span>
            </v-card-text>
            <v-row justify="center">
              <v-btn href="tel:+2347081271856" fab small text title="Telephone"
                ><v-icon>mdi-phone</v-icon></v-btn
              >
              <v-btn href="wa.me:+2347081271856" fab icon small text color="success" title="Whatsapp"
                ><v-icon>mdi-whatsapp</v-icon></v-btn
              >
              <v-btn href="t.me:+2347081271856" icon fab small text color="info" title="Telegram"
                ><v-icon>mdi-telegram</v-icon></v-btn
              >
            </v-row>
          </div>
        </v-col>
        <v-col cols="12" class="success text-center white--text">
          <div>
            &copy; {{ new Date().getFullYear() }}- Peace House Agricultural
            Training Institute
          </div>
        </v-col>
      </v-row>
    </v-footer>
</template>

<script>
    export default {
        
    }
</script>