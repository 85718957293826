<template>
	<div>
   <v-card elevation="3" class="pa-3">
            <h2 class="success white--text text-center mb-3">LOGIN</h2>

            <v-text-field
						v-model="dform.app_no"
              prepend-inner-icon="mdi-account-circle"
              label="Application Number"
							:rules="[v => !!v || 'Item is required']"
              outlined
              dense
            >
            </v-text-field>
            
            <v-text-field
						v-model="dform.passwd"
              prepend-inner-icon="mdi-lock"
              label="Password"
              outlined
              dense
							:rules="[v => !!v || 'Item is required']"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
            >
            </v-text-field>
            <v-divider class="ma-2"></v-divider>
            <v-row justify="center" class="mt-3">
              <v-btn color="success" :loading="loading" :disabled="!isValid"  class="mb-2" @click="processLogin">
                Login
              </v-btn>
            </v-row>
          </v-card>

					<v-snackbar v-model="snackbar" bottom :color="snackColor">
					<p>{{snackMsg}}</p>	
					<template v-slot:action="{ attrs }">
        <v-btn color="dark" v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
						</v-snackbar>
	</div>
</template>

<script>
import {snackMixin} from "@/mixins";
import { apiClient } from "@/services";
	export default {
		name:"LoGin",
mixins: [snackMixin],

		data() {
			return {
				showPassword: false,
				dform:{
					app_no:"",
					passwd:"",
				}
			}
		},

		computed: {
  isValid (){
    const reqf= ['app_no','passwd']
    return reqf.every(v => !!this.dform[v])
    }
},

		methods: {
			processLogin() {
        this.loading = true;
			apiClient
        .post("/login", this.dform)
        .then(res => {
          if (res.data.id) {
            if(res.data.submitted){
              this.$router.push({
                name:"ApplicantDashboard",
                params:{editUser: res.data}
              }); 
            }
            else{
              this.$router.push({
                name:"Application",
                params:{editUser: res.data}
              })
            }
          } else{
						this.displayMsg(res.data, "error");
					}
        })
        .catch(err => this.displayMsg(err.message, "error"));
				
			}
		},
		
	}
</script> 